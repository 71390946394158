<template>
  <div class="my-account">
    <div class="mya-item">
      <h2>3. Data Management</h2>
      <div class="mya-wrap">
        <div v-if="userLevel === 2 || userLevel === 3">
          <div class="myai-text">Download the data tables:</div>
          <div class="buttons">
            <a href="javascript:" class="myai-btna" @click="downloadTable('activity')">Download Activity Table</a>
            <a href="javascript:" class="myai-btna" @click="downloadTable('answers')">Download Answers Table</a>
            <a href="javascript:" class="myai-btna" @click="downloadTable('articles')">Download Articles Table</a>
            <a href="javascript:" class="myai-btna" @click="downloadTable('comments')">Download Comments Table</a>
            <a href="javascript:" class="myai-btna" @click="downloadTable('points')">Download Points Table</a>
            <a href="javascript:" class="myai-btna" @click="downloadTable('questions')">Download Questions Table</a>
            <a href="javascript:" class="myai-btna" @click="downloadTable('rejections')">Download Rejections Table</a>
            <a href="javascript:" class="myai-btna" @click="downloadTable('users')">Download Users Table</a>
            <a href="javascript:" class="myai-btna" @click="downloadTable('admin_record')">Download Admin Record Table</a>
            <a href="javascript:" class="myai-btna" @click="downloadTable('order_record')">Download Order Record Table</a>
            <!-- <a href="javascript:" class="myai-btna" @click="simulateDownload('slow-test')">Simulate Slow Download</a> -->
          </div>
        </div>
        <div v-else>
          If you need data, please contact us
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { post } from "../../api";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

export default {
  name: 'DataManagement',
  data() {
    return {
      userLevel: null,
    };
  },
  mounted() {
    this.getUserLevel();
  },
  methods: {
    getUserLevel() {
      post('/user/logged')
        .then(response => {
          this.userLevel = response.user.admin;
        })
        .catch(error => {
          console.error('Unable to check your status', error);
        });
    },
    downloadTable(tableName) {
      NProgress.start();
      post('/admin/download_data', { table: tableName }, {
        responseType: 'blob',
        onDownloadProgress: progressEvent => {
          const total = progressEvent.total;
          const current = progressEvent.loaded;
          const percentage = Math.round((current / total) * 100);
          NProgress.set(percentage / 100);
        }
      })
      .then(response => {
        NProgress.done();
        if (response.data instanceof Blob) {
          const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
          const downloadUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', `${tableName}.csv`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(downloadUrl);
        } else {
          console.error('Response is not a Blob:', response.data);
        }
      })
      .catch(error => {
        NProgress.done();
        console.error('Error downloading file:', error);
      });
    },
    // simulateDownload(tableName) {
    //   simulateSlowDownload(tableName).then((blob) => {
    //     const downloadUrl = URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.href = downloadUrl;
    //     link.setAttribute('download', `${tableName}.csv`);
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     URL.revokeObjectURL(downloadUrl);
    //   });
    // }
  }
}

// function simulateSlowDownload(tableName) {
//   return new Promise((resolve) => {
//     const total = 1024 * 1024; // 1 MB
//     let loaded = 0;
//     const chunkSize = 50 * 1024; // 50 KB
//     const interval = 100; // 100 ms per chunk

//     function downloadChunk() {
//       loaded += chunkSize;
//       if (loaded < total) {
//         NProgress.set(loaded / total);
//         setTimeout(downloadChunk, interval);
//       } else {
//         NProgress.done();
//         const blob = new Blob(['This is a simulated CSV file for ' + tableName], { type: 'text/csv;charset=utf-8;' });
//         resolve(blob);
//       }
//     }

//     NProgress.start();
//     downloadChunk();
//   });
// }
</script>


<style scoped>
.my-account {
  padding: 35px 65px 25px 65px;
}
.mya-item {
  margin-bottom: 45px;
}
.mya-item h2 {
  font-size: 18px;
  font-family: "Poppins-SemiBold";
  color: #111880;
  margin-bottom: 20px;
}
.mya-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.myai-text {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 20px;
}
.buttons {
  display: flex;
  flex-direction: column;
}
a.myai-btna {
  display: block;
  width: 250px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  color: #ffffff;
  border-radius: 20px;
  font-family: "Poppins-SemiBold";
  background-color: #5473ff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
a.myai-btna:hover {
  background-color: #111880;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}
</style>
